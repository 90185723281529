.notVisible
{
  visibility: hidden;
  min-height: 1px;
}

li[disabled] {
  pointer-events : none;

  span {
    color: #26a69a;
  }
}

.team-logo{
  position: relative;
  top: 5px;

}

.dropdown-click{
  cursor: pointer;
}


.modal{

  .modal-content{
    p{
      font-size: 1em;
    }
  }

  .modal-footer{
    .waves-green{
      background-color: #26a69a;
      color: white;
      margin: 6px 20px;
    }
    .waves-red{
      background-color: #ee6e73;
      color: white;
    }
  }
}


.feldspieler{



  img.gelbGesperrt + span::after{
    content: "\00a0";
    display: block;
    background-image: url('/img/gelb.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0px;
    right: 0px;
    margin-top: -20px;
    height: 1em;
    width: 20px;
    z-index: 999;
  }

  img.rotGesperrt + span::after{
    content: "\00a0";
    display: block;
    background-image: url('/img/rot.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0px;
    right: 0px;
    margin-top: -20px;
    height: 1em;
    width: 20px;
    z-index: 999;

  }

  img.gelbRotGesperrt + span::after{
    content: "\00a0";
    display: block;
    background-image: url('/img/gelb_rot.png');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    top: 0px;
    right: 0px;
    height: 16px;
    width: 16px;
    z-index: 999;

  }



}

.ereignis-button{
  padding: 0 !important;
  width: 100% !important;
}

.dropdown-content{
  min-width: 200px;

}

@media #{$small-and-down} {
  #spieltag{

    .ereignis-row{
      border: 1px solid #c2c2c2;
      border-spacing: 5px;
      border-radius: 2px;
      padding-top: 10px;
      word-break: break-all;
    }


    #buttonCell{
      padding: 0;
      button.waves-effect.btn{
        padding: 0 10px;
      }
    }

    .table-wrapper{
      .dropdown-wrapper
      {


        &.right-dropdown{
          right: 60%;
        }
      }

    }

    .team-logo{
      max-height: 2em;
      width: auto;
      max-width: 41%;

    }

  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .team-logo {

  }
}

@media #{$large-and-up} {
  .team-logo{
    width: 52px !important;
    float: left;
    top: 10px;
    position: absolute;

    &.left{
      left: -21px !important;
    }
  }
}

/*    width: 52px;
    float: left;
    position: absolute;
    /* left: -21px; */
