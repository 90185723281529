table {
  &.kalender {
    font-size: 0.85em;
    width: 100%;
    table-layout: fixed;

    td {
      position: relative;
      background-color: #f3f3f3;
      border: 2px solid #FFFFFF;

      div {
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        overflow: hidden;
        display: inline;

        .fa {
          display: inline;
          margin-right: 0.5em;
        }

      }
      &:before {
        content: "";
        display: block;
        padding-top: 100%; /* ratio of 1:1 */
      }

      &.trans[data-day]:after{
        color: $grau_schwarz;
      }

      &[data-day]:after {

        content: attr(data-day);
        position: absolute;
        bottom: 0;
        right: 10px;
        z-index: 0;
        color: $grau_schwarz;
        //font-size: 4em;
        font-weight: 100;
      }

      &.trans {
        background-color: transparent;

        &:after {
          color: $primary-color;
        }
      }
    }
  }
}

/*
Inspired and adopted from https://css-tricks.com/responsive-data-tables/

Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 1024px)  {
  table {
    &.kalender {
      display: block;
      thead, tbody, th, td, tr {
        display: block;
      }
      thead {
        /* Hide table headers (but not display: none;, for accessibility) */
        tr {
          position: absolute;
          top: -9999px;
          left: -9999px;

        }
      }
      tbody {
        tr {
          margin: 1em 0;
          td {
            /* Behave  like a "row" */
            border: none;
            position: relative;
            padding: 0 1em 2em 1em;
            margin: 1em 0;
            &:before {
              padding-top: 0;
            }
            &[data-day]:after {
              font-size: 2em;
            }
            &.hide-date {
              display: none;
            }
            div {
              position: relative;
            }
          }
        }

      }
    }
  }
}