$gruen_dunkel: rgb(144,211,137);
$gruen_hell: rgb(186,233,195);

$rot_dunkel: rgb(255,57,68);
$rot_hell: rgb(255,138,141);

$spielfeld_rasen: rgb(156,218,185);

$grau: rgb(215,215,215);
$grau_dunkel: rgb(243,243,243);
$grau_schwarz: rgb(121,121,121);
$schwarz: #000;


$primary-color: rgb(0,137,199);
$primary-color-light: lighten($primary-color,5%);
$primary-color-dark: darken($primary-color,5%);
$link-color: rgb(0,137,199);

$sidenav-width: 240px;


$input-focus-color: $primary-color;

$fa-css-prefix:       fa !default;

.dropdown-content{
  z-index: 997 !important;
}


// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;


$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
