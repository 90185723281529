@import "vars";
@import "tabellen";
@import "kalender";
@import "begegnung";
@import "spieltag";
@import "spinning_wheel";
@import "../../../node_modules/cropper/src/scss/cropper";

html {
  font-size: 100%;
}

body {
  color: black;
}

.profilepicture-wrapper {
  max-height: 800px;
}

@media screen and (max-width: 768px) {
  .profilepicture-wrapper {
    height: 400px;
  }
}

@media screen and (max-width: 468px) {
  .profilepicture-wrapper {
    height: 200px;
  }
}

#foto-view {
  max-width: 1300px;
}

#register-errors {
  padding-left: 30px;
}

img {
  &.karte-sperre {
    height: 16px;
    width: auto;
    margin-bottom: -0.2em;
    &-klein {
      height: 1em;
      width: auto;
      margin-bottom: -0.1em;
    }
  }
  &.profilbild {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &.verein_dashboard {
    width: 64px;
    max-width: 64px;
    height: 64px;
    max-height: 64px;
  }
  &.kaderfoto {
    width: 50px;
    max-width: 50px;
    height: 50px;
    max-height: 50px;

    &-klein {
      width: 25px;
      max-width: 25px;
      height: 25px;
      max-height: 25px;
      margin: 0.25em 0;
    }
    &-mini {
      width: 1em;
      max-width: 1em;
      height: 1em;
      max-height: 1em;
      margin: 0.25em 0;
    }
  }
}

.new.badge {
  background-color: #ED1C24;
}

.versus {
  font-size: 3em;
  font-weight: bold;
}

#abwesendeSpieler .input-field {
  margin-bottom: 15px;
}

div[id^='wid-id-spielerliste-'],
#wid-id-gegner-liste,
#wid-id-mitarbeiter-liste,
div[id^='wid-id-archiv-liga-'],
div[id^='wid-id-spielerliste-'],
#wid-id-training,
div[id^='wid-id-telefonliste-'],
#wid-id-admin-user-list,
#wid-id-begegnungen,
{
  .widget-body {
    overflow-x: auto;
  }
}

#stats {
  overflow-x: auto;
}

.spieltag-foto {
  height: 30px;
  width: 30px;
}

.circle {
  border-radius: 50%;
}

.container.white-container {
  width: 100%;
}

.farbe {
  &-rot{
    color: #FF0000;
  }

  &-weiss{
    color: #FFFFFF;
  }

  &-blau{
    color: $primary-color;
  }

  &-gruen{
    color: #1AC07E;
  }

  &-grau-dunkel{
    color: $grau_schwarz;
  }

  &-grau{
    color: $grau;
  }

  &-gold{
    color: #ffeb3b;
  }
}

.bg {
  &-rot {
    background-color: $rot_dunkel;
  }
}

.imageoverlay {
  margin-top: 10px;
  position: relative;
  display: inline-block;
  width: 100%;
  .topimage {
    position: absolute;
    bottom: 0px;
    left: 0;
    max-width: 80px;
    max-height: 50px;
  }
  >img:first-child{
    float: left;
  }
  .btn{
    position: absolute;
    bottom: 0px;
    right: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none    ;
    box-shadow: none;

    &:active {
      top: unset;
      left: unset;
    }

    i{
      line-height: 1.9em;
      font-size: 1.9em;
    }
  }
}

.header {
  form {
    display: inline;
  }
}

@media #{$small-and-down} {
  .header {
    .btn {
      margin-left: 0.5rem;
    }
  }
}

.plz-display-fix{
  margin-bottom: -1px;
}

@media (min-width: 750px) and (max-width: 1679px) {
  .section {
    > .col {
      &.m12.l6.profile-width-fix {
        width: 100% !important;
      }
    }
  }
  .col {
    &.m6.l3.profile-width-fix {
      width: 50% !important;
    }
  }
}

.btn {

  &.small {
    padding: 0 0.5em;
  }
  &.btn-white {
    background-color: #FFFFFF;
    color: $primary-color;
  }
}

// Text Align
.left-align {
  text-align: left;
}
.right-align {
  text-align: right
}
.center, .center-align {
  text-align: center;
}

.left {
  float: left !important;
}
.right {
  float: right !important;
}

.smart-form .col.col-12 {
  width: 100%;
}


.right-form {
  background: white;
  margin-left: 10px
}

.stars>h2{
  margin: 0;
  text-align: center;
  font-size: 3.56rem;
  line-height: 110%;
}

.inline {
  display: inline-flex;
}

.smart-form {
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .c-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .c-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .c-slider {
    background-color: $rot_dunkel;
  }

  input:focus + .c-slider {
    box-shadow: 0 0 1px $rot_dunkel;
  }

  input:checked + .c-slider:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }

  /* Rounded sliders */
  .c-slider.round {
    border-radius: 34px;
  }

  .c-slider.round:before {
    border-radius: 50%;
  }
}

#wid-id-social-media .social-media-input-wrapper,
#wid-id-mitarbeiter-social-media .social-media-input-wrapper {
  padding: 15px;
}

.verein-form section.col {
  padding: 0;
}

#wid-id-kalender-insert-update, #training-insert-update {

  .halbieren > label.select {
    width: calc(50% - 10px);
    float: left;
  }

  form .row {
    margin: 0 -5px;
  }

  .row > div label {
    margin: 0 5px;
  }
}

.backend-setting-language {
  @media #{$medium-and-up} {
    > [class*="col-"] {
      max-width: 200px;
    }
  }

  .well {
    text-align: center;

    img {
      width: 100%;
      margin: 10px 0;
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      margin: 0;
    }

    input[type=submit] {
      display: inline-block;
    }
  }
}

.smart-form .row {
  margin-bottom: 5px;
}

.header.spieler {
  min-height: 28px;
}

.side table th,
.side table td {
  padding: 0 8px 8px 0;
}

.modal-footer form {
  display: inline;
}

#logo-group #logo {
  margin: 8px 0 0 8px;
  height: 34px;

  img {
    width: auto;
    height: 100%;
  }
}

.header-dropdown-list .dropdown-menu li img {
  margin-right: 5px;
}

#footer-social-media-links {
  text-align: right;

  a {
    font-size: 18px;
    margin-left: 10px;
  }
}

@media #{$small-and-down} {
  .page-footer {
    height: auto;
  }

  #footer-copyright {
    text-align: center;
  }

  #footer-social-media-links {
    text-align: center;
    margin: 10px 0;
  }
}

#login-form ul {
  list-style-type: none;
  margin-bottom: 10px;
}

#extr-page #header #logo img {
  width: auto;
}

@media #{$large-and-up} {
  #see-news > div:nth-child(3n + 1) {
    clear: left;
  }
}

@media #{$medium-and-down} {
  #see-news > div:nth-child(2n + 1) {
    clear: left;
  }
}

.modal-content {
  text-align: left;
}

.fa-pencil {
  color: $primary-color;
}

.legacy-css {
  .header {
    color: unset !important;
  }

  .material span {
    font-size: 14px !important;
  }

  span.caret {
    border-top: unset;
    font-size: 10px !important;
  }

  .select-wrapper input.select-dropdown {
    font-size: 14px !important;
    line-height: 34px !important;
    height: 34px !important;
  }

  .dropdown-content li > a, .legacy-css .dropdown-content li > span {
    font-size: 14px !important;
  }

  .caret {
    right: 8px !important;
  }
}

#wid-id-begegnung-spieltag-einzel-torschuetzen td,
#wid-id-begegnung-spieltag-einzel-vorlagengeber td,
#wid-id-begegnung-spieltag-einzel-scorer td {
  line-height: 28px;
}

#action-buttons {
  display: inline-block;
  float: right;
  padding-top: 4px;
}

#ribbon .breadcrumb {
  padding-right: 11px !important;
}

#spieltag button.ereignis-button {
  background: unset;
  border-radius: unset;
  box-shadow: unset;

  &.visible-xs {
    width: 100%;
    background: #FF0001;
    margin-top: 10px;

    i {
      color: white !important;
    }
  }

  &:hover i {
    color: #26a69a;
  }

  i {
    color: #0089c7
  }

  i.fa-times {
    color: #FF0001;
  }
}

#wid-id-spieltag-benotung input.name {
  font-size: 14px;
  height: 34px;
}

#wid-id-spieltag-datum .widget-body,
#wid-id-spieltag-uhrzeit .widget-body {
  min-height: unset;
}

#spieltag {
  max-width: 1500px;

  .side {
    max-width: 400px;
  }
}

.display-image {
  margin-top: -36px;
  margin-right: 0;
  width: 268px;
}

@media screen and (min-width: 1200px) {
  .display-image {
    width: 350px;
  }

  .register-page .display-image {
    width: 300px;
  }
}

#wid-id-begegnung-spieltag-liste {
  #inputForm {
    label.select {
      width: 100%;
    }

    button {
      text-transform: uppercase;
    }
  }
}

#trigger-gamefield-slide {
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  z-index: 100;

  &.open {
    right: unset;
    left: 30px;
    top: 30px;
    z-index: 1100;
  }
}

#gamefield-modal .modal-dialog {
  margin: 0;
  width: 100%;
  height: 100%;

  .modal-content, .modal-body {
    height: 100%;
  }
}

.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
}

#leistungsdaten {
  #add-saison {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
  }

  #saisons {
    .row {
      margin-bottom: 20px;
      width: calc(100% - 10px);
      float: left;
    }

    .remove {
      margin-top: 26px;
      font-size: 22px;
      float: right;
      cursor: pointer;
    }
  }
}

$color-green-hover: #169360;

.table-hover>tbody>tr:hover {
  background-color: $color-green-hover;
}

#email-compose-form {
  .control-label.fixed-form-value {
    padding: 7px 7px 7px 14px;
  }
}

#inbox-content {
  min-height: 250px;

  .inbox-data-message > :first-child > :first-child:after {
    content: unset;
  }

  .inbox-data-date {
    width: 115px;
    text-align: right;
  }
}

#mail-inbox-table {
  tr {
    cursor: pointer;
    td {

      padding: 6px 4px 7px !important;
    }
  }

  .unread td {
    background: unset;
  }
}

#autograph-modal {
  @media (min-width: 768px) {
    .modal-dialog {
      width: 800px;
    }
  }

  #autogramm-version-auswaehlen {
    .col {
      margin-bottom: 20px;
    }

    .autogramm-version {
      width: 100%;
      cursor: pointer;

      &:hover {
        outline-style: solid;
        outline-color: grey;
      }

      &.selected {
        outline-style: solid;
        outline-color: $primary-color;
      }
    }
  }
}

.workout-show-image {
  max-width: 100%;
  max-height: 400px;
}

.workout-post-item {
  border: 2px solid black;
  padding: 12px;
  width: 300px;
  margin: 8px;
  position: relative;

  &.premium-post {
    border-width: 3px;
    border-color: green;
  }

  .workout-post-badge {
    position: absolute;
    right: 12px;

    &.workout-post-badge-free {
      width: 70px;
      bottom: 48px;
    }

    &.workout-post-badge-premium {
      width: 120px;
      bottom: 54px;
    }
  }

  @media (max-width: 400px) {
    width: 100%;
  }

  .workout-post-item-title {
    text-overflow: ellipsis;
    font-weight: bold;
    overflow: hidden;
    height: 36px;
  }

  .workout-post-item-image {
    width: 100%;
    height: 170px;
    margin-bottom: 8px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .workout-post-item-button {
    width: 100%;
    text-align: right;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
}

#wid-id-verein-profile-fotos .imageoverlay {
  width: auto;
  height: 200px;

  img.profilbild {
    width: auto;
    height: 100%;
  }
}

#wid-id-gegner-insert-update h1 {
  padding: 0 25px;
}

#workout-posts-free-premium-hide-boxes {
  padding-left: 6px;
  margin-bottom: 14px;

  .radio i {
    margin-top: 7px;
  }
}

#wid-id-begegnung-liga-spieltag {
  .content {
    min-height: 400px;
  }

  .spieltag-dropdown {
    float: right;
    width: 150px;
    margin-bottom: 20px;
  }
}

#wid-id-begegnung-liga-spieltag, #wid-id-begegnung-liga-tabelle {

  .table-hover > tbody > tr:hover {
    background-color: #169360 !important;
  }

  .heim, .gast, .vereinsname {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 200px;
  }

  > div {
    background-image: url('../img/liga-tabelle-hintergrund.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    max-width: 850px;
    margin: 0 auto;
    padding: 14px;
    color: white;
    margin-bottom: 8px;
    width: 100%;
    overflow-x: auto;
    /* Blur */
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    /* Opacity color */
    background-color: rgb(94,94,94);
    background-color: rgba(94,94,94, 0.4);

    header {
      display: block;
      width: 100%;
      height: 40px;

      .liga-logo {
        height: 100%;
        float: left;
      }

      .tabelle-title {
        float: left;
        margin-left: 14px;
      }

      .liga-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0;
      }

      .spieltag {
        text-transform: uppercase;
      }
    }

    table {
      width: 100%;

      td, th {
        padding: 6px 10px;

        &:not(:first-child):not(:nth-child(2)) {
          text-align: center;
        }
      }

      tr {
        color: black;
        border-width: 1px 0;
        border-style: solid;
        border-color: white;
        background-color: rgb(255,255,255);
        background-color: rgba(255,255,255, 0.4);
      }
    }
  }

  footer {
    .red-line {
      border-top: 4px solid black;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
    }

    .fbtv {
      font-weight: bold;
      color: white;
      text-align: center;
      background: black;
      width: 240px;
      margin: 0 auto;
      margin-bottom: -10px;
    }
  }
}

#wid-id-freundschaftsspiel-einstellungen, #freundschaftsspiele-datum-filter {
  .form-group {
    margin-bottom: 15px;
  }

  .datepicker {
    padding: 6px 12px;
    box-sizing: border-box;
  }
}

#ui-datepicker-div {
  z-index: 10 !important;
}

#edit-own-game-button {
  background: #3276b1;
  width: 14px;
  height: 14px;
  padding: 6px 8px 10px 10px;
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;

  i {
    color: white;
  }
}

.license-offer-box {
  border: 2px solid green;
  padding: 10px;

  .license-title {
    font-weight: bold;
    text-align: center;
    font-size: 16px;
  }

  .license-subtitle {
    text-align: center
  }

  img {
    width: 100%;
    margin-bottom: 10px;
  }
}

#wid-id-user-lizenz {
  max-width: 1000px;
}

#seriendauer {
  input[name=serienende]:disabled {
    cursor: not-allowed;
  }

  .input-label {
    margin-top: 8px !important;
  }
}

#markdownEditor {
  resize: vertical !important;
  height: 200px;
}
/*
.input-group-addon {
  width: 32px;
  margin-top: 0;
  float: left;
  height: 32px;
  padding-top: 8px;
}*/

#gewicht, #groesse, #schuhgroesse, #preis, #verlust_preis {
  float: left;
  width: calc(100% - 20px);

  &+ span {
    float: right;
    margin-top: 6px;
  }
}

#ie-detected {
  z-index: 999;
  background: yellow;
  position: fixed;
  width: 100%;
  text-align: center;
}

#wid-id-transfermarkt-liste {
  img.kaderfoto {
    width: 30px;
    height: 30px;
  }

  .transfermarkt-user-foto {
    height: 100%;
    float: left;
    padding-right: 6px;
  }
}

#add-text-inhalt {
  margin-top: 20px;
  width: 100%;
}

img.workout-post-item-video-inkl {
  position: absolute;
  height: 80px !important;
  right: -93px;
  top: 53px;
}

#gesuche_wrapper, #auf-anfrage_wrapper {
  overflow-x: auto;
}

#wid-id-club-show .foto-edit {
  margin-left: -40px;
  margin-top: 167px;
}

.help-video {
  i.fa {
    font-size: 18px;
  }
}

.modal-backdrop {
  z-index: 800;
}

#wid-id-training-invite {
  width: 100%;

  .widget-body {
    overflow-x: auto;
  }

  table {
    color: black;
  }

  .right-align .btn {
    padding: 4px 8px;
  }
}

#wid-id-training-invite table,
#wid-id-training-presence table {
  .fa-check {
    color: green;
  }

  .fa-times {
    color: red;
  }

  .fa-question {
    color: orange;
  }

  .btn-link {
    padding: 0;
    font-size: 12px;
  }

  form {
    display: inline-block;
  }
}

#wid-id-presence {
  #select-month {
    max-width: 100px;
    margin: 0 0 12px auto;
  }
}

.green-bordered-box {
  padding: 4px;
  border: 1px solid green;
  color: black;
}

.endpreis {
  color: darkgreen;
  font-weight: bold;
}

.black-link {
  color: black;
  text-decoration: underline;

  &:hover {
	color: black;
	text-decoration: underline;
  }
}
