.spinning-wheel {
  margin: 10% auto;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  border-right: 3px solid $primary-color;
  border-top: 3px solid $primary-color;
  border-radius: 100%;
  height: 125px;
  width: 125px;
  z-index: 999;
  -webkit-animation: spin .6s infinite linear;
  -moz-animation: spin .6s infinite linear;
  -ms-animation: spin .6s infinite linear;
  -o-animation: spin .6s infinite linear;
  animation: spin .6s infinite linear;
}

.spinning-container{
  z-index: 1000;
  position: fixed;
  width: calc(100% - 220px);
  height: 105%;
  background-color: #F3F3F3;
  right: 0;
  top: 90px;

  @media only screen and (max-width: 979px) {
    width: 100%;
  }

}

@keyframes "spin" {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-webkit-keyframes "spin" {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-ms-keyframes "spin" {
  from {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}

@-o-keyframes "spin" {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }

}