table {
    &.striped {
        thead {
            tr, th {
                background-color: transparent;
            }
        }
        tbody {
            tr{
                &:nth-child(odd) {
                    background-color: $grau_dunkel;
                }
                &.gruen_dunkel {
                    background-color: $gruen_dunkel;
                }
                &.gruen_hell {
                    background-color: $gruen_hell;
                }
                &.rot_dunkel {
                    background-color: $rot_dunkel;
                }
                &.rot_hell {
                    background-color: $rot_hell;
                }
            }
        }
    }

    &.highlight {
        tbody {
            tr {
                &:hover {
                    background-color: darken(#1AC07E,10%);
                }
            }
        }
    }
    
    &.responsive-table {
        &.tabelle, thead, tbody {
            th, td {

                &.left-align {
                    text-align: left;
                }

                &.right-align {
                    text-align: right;
                }
            }
        }
        &.left {
            th, td {
                text-align: left;

                &.center-align {
                    text-align: center;
                }

                &.right-align {
                    text-align: right;
                }
            }
        }
    }
    
    &.kalender {
        font-size: 0.85em;
        width: 100%;
        table-layout: fixed;
        margin-bottom: 15px;

        td {
            position: relative;
            background-color: #f3f3f3;
            border: 1px solid #000;
            background-clip: padding-box;

            div {
                position: absolute;
                top: 10px;
                right: 10px;
                bottom: 10px;
                left: 10px;
                overflow: hidden;
                display: inline;

                .fa {
                    display: inline;
                    margin-right: 0.5em;
                }

            }
            &:before{
                content: "";
                display: block;
                padding-top: 100%; 	/* ratio of 1:1 */
            }
            
            &[data-day]:after {

                content: attr(data-day);
                position: absolute;
                bottom: 0;
                right: 10px;
                z-index: 0;
                color: $grau_schwarz;
                font-size: 4em;
                font-weight: 100;
            }

            &.trans {
                background-color: transparent;

                &:after {
                    color: $primary-color;
                }
            }
        }
    }
    
    tr {
        &.danger {
            color: #ff0000;
        }

        td,th {
            border-radius: 0px;
            padding: 10px;

            &.komma {
                input[type=number] {
                    width: 90%;
                }
            }
        }
    }
    .gruen_dunkel {
        background-color: $gruen_dunkel !important;
    }
    .gruen_hell {
        background-color: $gruen_hell !important;
    }
    .rot_dunkel {
        background-color: $rot_dunkel !important;
    }
    .rot_hell {
        background-color: $rot_hell !important;
    }
    &.tabelle {
        tr {
            th:first-child {
                padding: 0 !important;
            }
            td:first-child {
                padding-right: 0;
            }
        }
    }
}

@media #{$medium-and-up} {
    table {
        &.responsive-table {
            &.tabelle, thead, tbody {
                th, td {
                    &.fixed-with {
                        &-3 {
                            width: 3%;
                        }
                        &-5 {
                            width: 5%;
                        }
                        &-6 {
                            width: 6%;
                        }
                        &-7 {
                            width: 7%;
                        }
                        &-9 {
                            width: 9%;
                        }
                    }
                }
            }
        }
    }
}
