//Spieltagübersicht einzel

#spieltag{

  h7{
    color: white;
  }

  .top-controll{

      input{
        top: -10px;
      }

    span.caret{
      top: 6px;
    }

  }

  #matches{
    margin-bottom: 133px;

    .foreignGame{
      &>input:first-child{
        text-align: right;
      }
    }


    .own-result{
      color: $primary-color;
      text-align: center;

      & .grey{
        pointer-events: none;
      }

      a{
        margin-top: 10px;
      }
    }

    .colon{
      text-align: center;
      margin-top: .75rem;
    }

    input{
      border-bottom: none;
    }
    /*input.goal1
    {
      width: 40%;
    }
    input.goal2
    {
      width: 40%;
      margin-left: 5%;
    }*/

    tbody{

      tr{

        td{
          padding: 0px 10px;
          height: 15px;

          .input-field{
            margin-top: 0px;

            input{
              margin-bottom: 0px;
            }
          }

        }


      }
    }


  }

  #ereignis-form{
    .rotInput{
      padding: 0;
      &>div>input{
        margin: 0;
      }
    }
  }

  .table-wrapper{
    overflow-y: hidden;
  }

  #matches{
    .row.grey{
    background-color: #f2f2f2 !important;
    }

    .row{
      margin-bottom: 0;
    }
    .head{
      text-align: center;
      font-weight: bold;
      border-bottom: solid 1px grey;
      border-spacing: 7px;
    }

    .vs{
      margin-top: 12px;
      text-align: center;
    }
  }

  .scoring-entry {
    position: relative;
    padding-left: 40px;

    .spieltag-foto {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
    }

    @media(min-width: 993px) and (max-width: 1120px) {
      padding-left: 0;

      .spieltag-foto {
        position: relative;
        text-align: center;
        display: block;
        transform: none;
      }
    }
  }



}

.table-logo{
  height: 1.5em;
  width: auto;
  margin-right: 10px;
}

@media #{$medium-and-up} {
  #spieltag-table {
    tbody{
      tr{
        &>td:nth-child(1), &>td:nth-child(3), &>th:nth-child(1), &>th:nth-child(3){
          width: 35%;
        }

        &>td:nth-child(2), &>th:nth-child(2){
          width: 5%;
          text-align: center;
        }

        &>td:nth-child(4), &>th:nth-child(4){
          width: 25%;
        }
      }
    }
  }
  .mobileButton{
    display: none;
  }
}

@media #{$large-and-up} {
  #spieltag{
    #spieltag-table{
      /*input.goal1
      {
        width: 43%;
      }*/
    }
  }
}

@media #{$small-and-down} {
  #spieltag, #stammelf{
    .top-controll{

      input{
        margin: 0;
        margin-top: 15px;
      }


    }

    .tabelle{
      min-width: 560px;
    }

    #matches{
      .own-result{


        a{
          padding: 0 1rem !important;
          margin-top: 60px;
        }


      }

      .vs{
        margin-bottom: 12px;
      }

      .foreignGame{
        input{
          text-align: center !important;
        }
      }
    }



  }

  .desktopButton{
    display: none;
  }

  .mobileButton{
    button{
      width: 100%;
    }
  }



}
